<template>
  <div class="job-detail app_content">
    <div class="warp_body">

      <div>
        <van-cell title="标题" :value="job.jobTitle" title-style="font-weight:700;font-size:16px"/>
        <div class="contactItem">

          <div class="contactItemImg">
            <van-image
                width="40"
                height="40"
                :src="job.companyImg"
            />
          </div>

          <div class="contactItemPerson">
            <div class="contactItemPersonName">{{ job.companyName }}</div>
            <div class="contactItemPersonNo">{{ job.companyContact }}</div>
          </div>

          <div class="contactItemPhone">
<!--            <van-button round color="#4DBAFD" plain size="small" >查看电话</van-button>-->
            <van-button round color="#4DBAFD" size="small" @click="call()">联系电话</van-button>
          </div>

        </div>

        <div style="background-color: #FFFFFF;padding:5px 50px">
          <div class="contactExplain">
            <div>联系时请说明是在“伯训通”看到的</div>
          </div>
        </div>

        <div class="jobItem">
          <div class="jobItemHead">项目描述</div>
          <div class="jobItemContent">
            <div>
              {{ job.jobContent }}
            </div>
            <div class="jobItemContentKeyword">关键词：</div>
            <van-tag color="#4DBAFD" style="margin-left:  5px" plain :key="item" v-for="item in job.keyword">{{
                item
              }}
            </van-tag>

          </div>
          <div>
            <div class="jobItemHead">项目地址</div>
            <div class="jobItemAddress">{{ job.companyAddress }}</div>
          </div>
        </div>
      </div>
      <div class="nearbyWork">
        <div class="line">
          <img src="../img/nearby_line.png">
        </div>
        <div class="lineText">
          附近适合您的工作
        </div>
        <div v-for="item in jobList" :key="item" style="padding: 7px">
          <JobCard :job="item"></JobCard>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRoute, useRouter} from 'vue-router'
import req from "../request/request";
import API from "../api/User";
import API_ORDER from "../api/Order";
import router from "../router";
import JobCard from "@/components/common/JobCard";
import utils from "@/util/utils";
import UserReq from "../api/UserReq";

export default {
  name: 'JobDetail',
  components: {JobCard},
  setup() {

    onBeforeMount(() => {
      const route = useRoute();
      let jobId = route.params.jobId
      methodsMap.getJobDetail(jobId)
      methodsMap.getjobList()
    });

    const data = reactive({
          job: {},
          jobList: []
        },
    )

    const methodsMap = {
      call: () => {
        let reqJson = {
          "workerUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "companyUid": data.job.companyUid,
        }
        UserReq.getSecretNo(reqJson)


      }, getJobDetail: (jobId) => {
        req.request(API_ORDER.jobDetail + jobId, null, {method: 'GET'}).then((res) => {
          data.job = res.data.data
          data.job.companyContact = data.job.companyContact.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
        })
      },
      getjobList: () => {
        req.request(API_ORDER.jobList, null, {method: 'GET'}, true).then((res) => {
          let list = res.data.data.records
          data.jobList = []
          list.forEach(
              (d) => {
                utils.getArea(d.companyArea).then(
                    v => {
                      d.companyArea = v;
                      data.jobList.push(d)
                    }
                )

              }
          )
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.app_content {
  .warp_body {
    .nearbyWork {
      margin-top: 20px;

      .line {
        text-align: center;
        width: 100%;

        &Text {
          position: relative;
          top: -32px;
          left: 128px;
          font-size: 16px;
        }
      }
    }

    .jobItem {
      padding: 10px;

      &Head {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      &Address {
        background-color: #FFFFFF;
        color: #777777;
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
      }

      &Content {
        background-color: #FFFFFF;
        color: #777777;
        border-radius: 10px;
        padding: 10px;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 16px;

        &Keyword {
          display: inline-block;
          font-weight: 700;
          color: #000;
          margin-top: 20px;
          font-size: 14px;
        }
      }
    }

    .contactExplain {
      color: #DA9A0A;
      text-align: center;
      font-size: 13px;
      background-color: #F9F9F9;
      border-radius: 10px;
    }

    .contactItem {
      background-color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      &Img {
        position: relative;
        left: 10px;
      }

      &Person {
        position: relative;
        right:50px;
        top: 3px;

        &No {
          font-size: 12px;
          color: #AAAAAA;
        }

        &Name {
          font-size: 16px;
          color: #333333;
        }
      }

      &Phone {
        position: relative;
        right:13px;
        top: 8px;
      }
    }
  }
}
</style>
